body {
  margin: 0;
}

@font-face {
  font-family: "UniNeue Bold";
  src: local("UniNeue Bold"),
    url(./fonts/Fontfabric-UniNeueBold.ttf) format("truetype");
}

@font-face {
  font-family: "UniNeue Book";
  src: local("UniNeue Book"),
    url(./fonts/Fontfabric-UniNeueBook.ttf) format("truetype");
}

@font-face {
  font-family: "UniNeue Light";
  src: local("UniNeue Light"),
    url(./fonts/Fontfabric-UniNeueLight.ttf) format("truetype");
}
