.releaseContent {
  flex: 1 0 auto; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
}

.releaseHeader {
  width: 100%;
  height: 200px;
  background: linear-gradient(
    90deg,
    rgba(246, 246, 246, 1) 0%,
    rgba(190, 231, 248, 1) 27%,
    rgba(135, 216, 250, 1) 60%,
    rgba(145, 255, 201, 1) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(33, 33, 33, 1);
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  font-size: 16px;
  text-transform: capitalize;
}

#tabsBox,
#tabLink {
  text-decoration: none;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
  color: #212121;
}

#tabsRow {
  margin-top: -30px;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
}

#tabsBox {
  width: 80%;
  max-width: 80%;
  min-width: 80%;
  margin-left: 10%;
}

#tabBox {
  padding: 6px 0px;
  height: 28px;
  max-height: 28px;
  min-height: 28px;
  color: #212121;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  margin-right: 1em;
}

#tabBox:hover {
  background-color: RGBA(26, 26, 26, 0.2);
  color: rgba(33, 33, 33, 1);
}

#tabsBox .MuiTabs-indicator {
  background-color: #212121;
  height: 5px;
}

.MuiAccordionSummary-content {
  font-family: "Roboto", sans-serif;
  color: #26c6da;
}

.MuiAccordion-root {
  background-color: transparent !important;
  box-shadow: unset !important;
}

.MuiAccordionSummary-root {
  flex-direction: row-reverse;
}

.MuiAccordion-root:before {
  background-color: unset !important;
}

.MuiCollapse-root .Mui-expanded {
  margin-bottom: 4em;
}

.MuiAccordionDetails-root {
  font-family: "Roboto", sans-serif;
  padding-top: 0px !important;
  margin-bottom: 3em;
}

.MuiAccordionDetails-root h1 {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 1em;
}

.MuiAccordionSummary-expandIconWrapper {
  color: #26c6da !important;
}
