.gradientBack {
  width: 100%;
  height: 50%;
  background: linear-gradient(
    90deg,
    rgba(246, 246, 246, 1) 0%,
    rgba(190, 231, 248, 1) 27%,
    rgba(135, 216, 250, 1) 60%,
    rgba(145, 255, 201, 1) 100%
  );
  z-index: 0;
  position: absolute;
  top: 0;
}

.contentBoxDownload {
  display: flex;
  justify-content: space-evenly;
}

.downloadBox {
  width: 20em;
  height: 510px;
  background-color: #ffffff;
  box-shadow: 6px 5px 50px #000000;
  border-radius: 12px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 10px 35px;
  z-index: 1;
}

.versionInfoText {
  height: 95px;
  font-family: "Roboto", sans-serif;
  color: #404040;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
}

.link {
  font-family: "Roboto", sans-serif;
  color: #5ccedd;
  text-decoration: underline;
}

.width100 {
  width: 100%;
}
.marginBottom10 {
  margin-bottom: 10px;
}

.marginBottomDropDown {
  margin-bottom: 1.2em;
}

.selectComp fieldset {
  border-color: #212121;
}

.selectCompIcon {
  width: 1em;
  margin-right: 0.5em;
  vertical-align: middle;
}

.MuiMenuItem-root:hover {
  background-color: RGBA(33, 33, 33, 0.05) !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: RGBA(33, 33, 33, 0.1) !important;
}

.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #46c4da !important;
}

.imgBox {
  width: 40em;
  position: relative;
}

.imgBox .firstImg {
  width: 24em;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
}

.imgBox .secondImg {
  width: 32em;
  position: absolute;
  z-index: 0;
  right: 0;
}

.downloadBtn,
#enabledBtn,
#disabledBtn {
  width: 100%;
  height: 61px;
  font-size: 22px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

#enabledBtn {
  background-color: #46c4da;
}

#disabledBtn {
  background-color: rgba(144, 144, 144, 1);
}

.thankYouBox {
  height: 60%;
  margin-left: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.thankYouHeader {
  color: #4d4d4d;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 0;
}

.helpP,
.helpLink {
  color: rgba(33, 33, 32, 1);
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 200;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 0.1em;
}

.helpLink {
  color: #5ccedd;
  font-weight: 400;
  text-decoration: none;
}

@media only screen and (max-width: 1050px) {
  .gradientBack {
    height: 695px;
  }

  .contentBoxDownload {
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
  }

  .downloadBox {
    margin-top: 50px;
  }

  .imgBox {
    margin-top: 30px;
    height: 30em;
    width: 24em;
    margin-bottom: 30px;
  }

  .imgBox .firstImg {
    left: unset;
    bottom: 0;
  }

  .imgBox .secondImg {
    max-width: 400px;
    right: unset;
  }
}

@media only screen and (max-width: 750px) {
  .imgBox .firstImg {
    width: 360px;
    height: 220px;
  }

  .imgBox .secondImg {
    width: 360px;
    height: 300px;
  }
}
