#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f3f3f3;
}

.height100 {
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  justify-content: space-around;
}

.header {
  height: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  z-index: 1;
  padding: 0 10%;
}

.headerMainPage {
  font-size: 24px !important;
  font-family: "UniNeue Book" !important;
  text-transform: uppercase !important;
  white-space: unset !important;
  align-items: center;
  display: flex;
}

.headerMainPage img {
  margin-right: 0.2em;
}

.headerItem {
  margin-right: 2em;
  text-decoration: none;
  color: #4d4d4d;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  text-transform: capitalize;
  white-space: nowrap;
}

.headerItemBreak {
  margin-right: 1em;
}

.navLink:hover {
  color: #5ccedd;
}

.navLink:nth-child(1):hover {
  color: #909090;
}

.logoImg {
  width: 60px;
  height: 60px;
}

.navLink:nth-child(1) {
  display: flex;
  align-items: center;
}

.navLink span {
  margin-left: 10px;
}

.footer {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eaeaea;
  flex-shrink: 0; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  padding: 0 10%;
}

.footer a {
  color: rgba(33, 33, 33, 1);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: 1.25px;
  text-decoration: none;
}

@media only screen and (max-width: 700px) {
  .headerMainPage {
    font-size: 18px !important;
  }

  .headerItem {
    font-size: 16px;
    margin-right: 1em;
  }
}

@media only screen and (max-width: 450px) {
  .header {
    padding: 0;
  }
  .headerMainPage {
    font-size: 16px !important;
  }

  .headerItem {
    font-size: 14px;
    margin-right: 1em;
  }

  .footer {
    padding: 1%;
  }

  .logoImg {
    width: 40px;
    height: 40px;
  }
}
